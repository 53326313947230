<template>
  <div class="systems-wrap mb-5 text-center">
    <v-form
      ref="registerForm"
      v-model="valid"
      @submit.prevent="registerUser()"
    >
      <div>
        <p class="text-left text-lg-h6">{{ interfaceData.loginPopupText }}: <span class="red--text"><strong>*</strong></span></p>
        <v-text-field
            v-model="email"
            :rules="emailRules"
            background-color="white"
            color="black"
            rounded
            filled
            autofocus
            required
            light
            class="black--color"
          >
          <template #label>
            {{ interfaceData.email }} <span class="red--text"><strong>*</strong></span>
          </template>
        </v-text-field>
        <v-btn depressed rounded large
          :disabled="!valid"
          dark
          color="accent"
          class="btn"
        >
          {{ interfaceData.start }}
        </v-btn>
        <p class="text-left caption mt-5">{{ interfaceData.gdpr_disclamer }} <a :href="interfaceData.privacy_url" target="_blank" rel="noindex">{{ interfaceData.privacyPolicyText }}</a>.</p>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PreSelect',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  data: function () {
    return {
      loadingSelectionId: null,
      valid: false,
      email: '',
      emailRules: [
        v => !!v || this.interfaceData.emailRule1,
        v => this.emailRegex.test(v) || this.interfaceData.emailRule2
      ],
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }
  },
  computed: {
    ...mapGetters([
      'APIURL_REGISTER',
      'partnerURL',
      'interfaceData',
      'referredByDistributer',
      'isDistributer',
      'isLastStep'
    ]),
    selectedFieldKey: {
      get: function () {
        return this.$store.getters.selectedField
      },
      set: function (newVal) {
      }
    }
  },
  methods: {
    validateForm () {
      return this.$refs.registerForm.validate()
    },
    updateSelectedField (id = '') {
      this.trackEvent('SelectFieldOnStart', 'ButtonClick')
      this.$store.dispatch('CHANGE_FIELD', [id, this.partnerURL])
      this.loadingSelectionId = id
      const st = setTimeout(() => {
        this.loadingSelectionId = null
        this.$router.push({ path: '/' })
        clearTimeout(st)
      }, 1000)
    },
    registerUser () {
      console.log('STAAAAAAART BUTTON CLICKED!!')
      if (!this.validateForm()) {
        return
      }
      return this.axios.post(this.APIURL_REGISTER, {
        id: '',
        session_id: '',
        referred_by: this.referredByDistributer,
        partner: this.partnerURL,
        customerInfo: {
          user_email: this.email
        },
        partnerInfo: {
          name: this.partnerURL,
          email: this.interfaceData.contact_email,
          phone: this.interfaceData.contact_phone,
          address: this.interfaceData.contact_address
        }
      })
        .then((resp) => {
          // console.warn('SERVER RESPONSE: ', resp)
          if (resp.status) {
            this.$localStorage.set('user_data', JSON.stringify(resp.data))
            const st = setTimeout(() => {
              this.loadingSelectionId = null
              if (this.partnerURL === 'MESI') {
                this.$router.push({ path: '/' })
              } else {
                this.$router.push({ path: '/' + this.partnerURL })
              }
              // console.log('LOCAL STORAGE: ', JSON.parse(this.$localStorage.get('user_data')))
              clearTimeout(st)
            }, 200)

            // open shared dialog if it is distrubuter
            if (resp.data.is_distributer && !this.isLastStep) {
              this.openShareDialog()
            }
          }
        })
        .catch(err => {
          console.warn('sendEmail error: ', err)
          // this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
        })
    },
    isSelected (id) {
      return this.selectedFieldKey === id
    },
    btnColorString (id = '') {
      return 'accent'
    },
    loadingItem (id) {
      return this.loadingSelectionId === id
    },
    getDistributerShareURL () {
      const userData = JSON.parse(this.$localStorage.get('user_data'))
      const distributer = userData.id + 'd'

      const url = window.location.origin
      if (this.partnerURL === 'MESI') {
        return url + '/referral/' + distributer
      } else {
        return url + '/' + this.partnerURL + '/referral/' + distributer
      }
    },
    openShareDialog () {
      const configCode = this.getDistributerShareURL()
      this.$store.commit('SET_CONFIG_URL', configCode)

      this.trackEvent('ShareDistributer', 'ButtonClick')
      this.$store.commit('SHOW_SHARE_DIALOG')
    }
  }
}
</script>
