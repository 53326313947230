<template>
  <div>
    <div class="mb-3">
      <div class="d-lg-flex align-top">
        <div>
          <span class="text-h5 accent--text">{{ interfaceData.yourConfigComplete }}</span>
        </div>
        <div class="text-right pl-xl-5 my-3 mt-lg-0 align-self-end d-flex flex-grow-1">
          <v-btn depressed outlined rounded class="mx-1 mb-2" @click="editConfig">{{ interfaceData.edit }}</v-btn>
          <v-btn depressed outlined rounded class="mx-1" @click="newConfig">{{ interfaceData.startOver }}</v-btn>
        </div>
      </div>
      <div class="my-lg-5">
        {{ interfaceData.summaryDescription1 }}
      </div>
    </div>
    <div>
      <PriceDisplay />
      <InquiryActions />
    </div>
    <div class="caption">
    </div>
    <ShareDialog v-if="isShareDialogVisible" />
  </div>
</template>

<script>
import PriceDisplay from '@/components/PriceDisplay.vue'
import InquiryActions from '@/components/interactiveSteps/partials/InquiryActions.vue'
import ShareDialog from '@/components/dialogs/Share.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Summary',
  props: {
    fieldOfCareData: Array
  },
  components: {
    InquiryActions, ShareDialog, PriceDisplay
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'isShareDialogVisible'
    ])
  },
  methods: {
    editConfig () {
      this.trackEvent('Edit', 'ButtonClick')
      this.$store.commit('CHANGE_STEP', 0)
    },
    newConfig () {
      this.$store.commit('SHOW_RESET_DIALOG')
    }
  }
}
</script>
