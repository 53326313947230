<template>
  <div>
    <div class="img-wrap" :class="{ static: isStatic }">
      <v-overlay absolute color="transparent" class="primary--text text-center font-weight-regular" v-if="item.comingSoon" style="z-index: 0">
        {{ interfaceData.coming }}<br/>{{ interfaceData.soon }}
      </v-overlay>

      <v-overlay absolute color="transparent" class="primary--text text-center font-weight-regular" v-if="imgClass.includes('disabled') && item.customOverlayText" style="z-index: 0">
        {{ item.customOverlayText }}
      </v-overlay>

      <v-overlay absolute color="transparent" class="primary--text text-center font-weight-regular" v-if="isAvailableAsApp && !isStatic" style="z-index: 0; line-height: 1.2">
        <div class="pa-3">
          {{ interfaceData.itemAvailableAsSmartApp }}
        </div>
      </v-overlay>

      <v-img
        :src="src"
        @click="clickItem"
        class="mx-auto img"
        :class="imgClass"
        :alt="item.name"
        :lazy-src="lazyImgSrc">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-btn
        v-if="isSelected"
        icon
        right
        top
        absolute
        ripple
        data-cy-check-btn
        class="check-btn accent"
        @click="clickItem"
      >
        <v-icon color="white">mdi-check</v-icon>
      </v-btn>
      <v-tooltip top v-if="infoToUse(item, info) && !isStatic" max-width="200">
        <template v-slot:activator="{ on }">
          <v-btn class="white info-icon" icon absolute bottom right v-on="on">
            <v-icon color="grey lighten-1" x-large>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>{{ infoToUse(item, info) }}</span>
        <div v-if="isAvailableAsApp && !isStatic" class="font-italic">
          {{ interfaceData.itemAvailableAsApp }}
        </div>
      </v-tooltip>
    </div>
    <div class="text-center mt-3" v-if="(item.subtext || item.name) && !isStatic">
      <div>{{ item.subtext || item.name }}</div>
      <div v-if="item.type && !item.subtext" class="text-capitalize font-weight-light">{{ item.type }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'imgCircle',
  props: {
    systemData: Object,
    isAvailableAsApp: Boolean,
    imgClass: { type: String, default: '' },
    item: Object,
    src: String,
    isSelected: Boolean,
    info: String,
    isStatic: Boolean
  },
  data: function () {
    return {
      lazyImgSrc: require('@/assets/placeholder.jpg')
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData'
    ])
  },
  methods: {
    infoToUse (item, info) {
      return info || item.info
    },
    clickItem () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.img-wrap {
  position: relative;

  &:not(.static) {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.2;
    cursor: default;
  }

  .check-btn {
    top: 0;
    right: 0;
  }

  .info-icon {
    bottom: 0;
    right: 0;
    font-size: 2rem !important;
  }

  .img {
    border: 2px solid var(--v-secondary-lighten5);
    border-radius: 50%;
    height: 0;
    padding-bottom: 100%;
  }
}
</style>
