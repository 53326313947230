<template>
  <div class="start-div white--text min-ful-height" :style="bgStyles">
    <v-container class="pa-0 min-ful-height">
      <div class="text-div min-ful-height d-md-flex flex-column justify-center pa-5 mx-0 pa-lg-10" :class="{ 'flex-fill': $vuetify.breakpoint.smAndDown }">
        <div class="my-auto">
          <v-row class="pb-4 mx-0">
            <v-col cols="6" class="my-auto">
              <img :src="require('@/assets/logo.svg')" width="150" />
              <h1 class="font-weight-regular" style="font-size: 1.5rem">{{ interfaceData.appName }}</h1>
            </v-col>
            <v-col cols="6" class="my-auto">
              <img v-if="partnerLogo !== ''" :src="partnerLogo" width="230" />
            </v-col>
            <h2 class="font-weight-light headline text-lg-h5 my-3 my-xl-8">{{ interfaceData.startBuild }} <span class="font-weight-bold">{{ interfaceData.startmTablet }}</span> {{ interfaceData.startForPractice }}</h2>
          </v-row>
          <div>
            <PreSelect :systemData="systemData" :fieldOfCareData="fieldOfCareData" />
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import fieldOfCareData from '@/data/MESI/fields.json'
import PreSelect from '@/components/PreSelect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  components: {
    PreSelect
  },
  data: function () {
    return {
      bgStyles: {
        backgroundImage: 'url(' + require('@/assets/mTABLET_configurator-background.jpg') + ')'
      }
    }
  },
  mounted () {
    this.$localStorage.set('mConf_startPageShown', true)
  },
  computed: {
    ...mapGetters([
      'partnerURL',
      'interfaceData',
      'selectedField',
      'partnerLogo'
    ]),
    fieldOfCareData () {
      return fieldOfCareData
    },
    systemDataOk () {
      return typeof this.systemData !== 'undefined'
    },
    systemData () {
      if (typeof this.selectedField === 'undefined') {
        return {}
      }
      return this.getObjectWithIDFromArr(fieldOfCareData, this.selectedField)
    }
  }/* ,
  methods: {
    skip () {
      this.trackEvent('SkipFieldSelectOnStart', 'ButtonClick')
      if (this.partnerURL === 'MESI') {
        this.$router.push({ path: '/' })
      } else {
        this.$router.push({ path: '/' + this.partnerURL })
      }
    }
  } */
}
</script>

<style lang="scss" scoped>
.start-div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .text-div {
    background: rgba(0, 0, 0, 0.24);
    max-width: 550px;
  }
}

.c-divider {
  background: white;
  height: 5px;
}

.min-ful-height {
  min-height: 100vh;
}
</style>
