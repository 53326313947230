<template>
  <v-row class="m-block">
    <v-col
      cols="12"
      class="title"
      :data-cy="extNiceName(extModID)"
    >
      {{ extNiceName(extModID) }} <strong class="accent--text" v-if="extNiceName(extModID) !== 'MESI mTABLET'">({{ numberFormatter.format(ExtModSelectedAsSys === extModByID(extModID).id ? extModByID(extModID).fullPriceSys : extModByID(extModID).fullPriceExt) }})</strong>
    </v-col>
    <v-col cols="12">
      <div class="d-flex">
        <ul class="d-inline-block flex-grow-1" v-if="includesList(extModID)">
          <li v-for="(txt, i) in includesList(extModID)" :key="i">{{ txt }}</li>
        </ul>
        <div class="d-inline-block flex-grow-0">
          <v-img :src="imgPathExtMod(extModByID(extModID).img)" class="img" :lazy-src="lazyImgSrc" contain>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-divider class="mb-4"></v-divider>
      <div class="d-flex justify-space-between">
        <div>
          <ul>
            <li
              v-for="id in extModByID(extModID).applications.included"
              :key="id">
              {{ applicationByID(id).name }} {{ interfaceData.app }}
            </li>
            <li
              v-for="id in extModByID(extModID).accessories.included"
              :key="id">
              {{ accessoryByID(id).name }}
            </li>
          </ul>
        </div>
        <div class="text-right">
          <div
            v-for="id in extModByID(extModID).applications.included"
            :key="id"
            class="d-inline-block ml-2 mb-2 m-icon-wrap">
            <v-img
              :src="imgPathApps(applicationByID(id).imgVisual)"
              class="pdf-change-image"
              width="70"
              contain>
            </v-img>
          </div>
          <div
            v-for="id in extModByID(extModID).accessories.included"
            :key="id"
            class="d-inline-block ml-2 mb-2 m-icon-wrap">
            <imgCircle
              :item="accessoryByID(id)"
              :src="imgPathAccs(accessoryByID(id).img)"
              isStatic />
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import imgCircle from '@/components/imgCircle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PresentationBlock',
  props: {
    extModID: String
  },
  components: {
    imgCircle
  },
  data: function () {
    return {
      lazyImgSrc: require('@/assets/placeholder.jpg')
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'ExtModSelectedAsSys',
      'extModByID',
      'applicationByID',
      'accessoryByID',
      'numberFormatter'
    ])
  },
  methods: {
    extNiceName (id) {
      const obj = this.extModByID(id)
      return obj.name
    },
    appNiceName (id) {
      const obj = this.applicationByID(id)
      return obj.name
    },
    accNiceName (id) {
      const obj = this.accessoryByID(id)
      return obj.name
    },
    includesList (id) {
      const obj = this.extModByID(id)
      return obj.includesList
    },
    imgPathExtMod: path => require('@/assets/extModules/interactive/' + path),
    imgPathApps: path => require('@/assets/apps/visual/' + path),
    imgPathAccs: path => require('@/assets/accessories/visual/' + path)
  }
}
</script>

<style lang="scss" scoped>
$imgw: 70px;

.m-block {
  border: 1px solid var(--v-secondary-lighten5);
  border-radius: 1rem;
  margin-bottom: 1rem;

  .title {
    padding-bottom: 0;
  }
}

ul {
  vertical-align: top;
}

.m-icon-wrap {
  max-width: $imgw;
  vertical-align: top;
  margin-bottom: 3px;

  .caption {
    line-height: 1.2;
    margin-top: 3px;
  }
}

.img {
  border: 2px solid var(--v-secondary-lighten5);
  border-radius: 50%;
  max-width: $imgw;
}
</style>
