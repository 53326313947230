<template>
  <div class="share font-weight-light py-8" :class="{ 'd-none' : hide }">
    <div class="mb-3">{{ isLastStep ? interfaceData.currentConfigText : 'Share your own personal configurator: ' }}</div>
    <div>
      <v-form ref="shareForm" v-model="valid">
        <v-text-field
            v-model="email"
            :rules="emailRules"
            autofocus
            required
          >
          <template #label>
            {{ interfaceData.enter_email }} <span class="red--text"><strong>*</strong></span>
          </template>
        </v-text-field>
        <v-text-field
            v-if="showEmails>1"
            v-model="email2"
            :rules="additionalEmailRules"
            autofocus
            required
            class=""
          >
          <template #label>
            {{ interfaceData.enter_email }}
          </template>
        </v-text-field>
        <v-text-field
            v-if="showEmails>2"
            v-model="email3"
            :rules="additionalEmailRules"
            autofocus
            required
          >
          <template #label>
            {{ interfaceData.enter_email }}
          </template>
        </v-text-field>
        <v-text-field
            v-if="showEmails>3"
            v-model="email4"
            :rules="additionalEmailRules"
            autofocus
            required
          >
          <template #label>
            {{ interfaceData.enter_email }}
          </template>
        </v-text-field>
        <v-text-field
            v-if="showEmails>4"
            v-model="email5"
            :rules="additionalEmailRules"
            autofocus
            required
          >
          <template #label>
            {{ interfaceData.enter_email }}
          </template>
        </v-text-field>
        <v-btn depressed outlined rounded class="mx-1" @click="showEmails++">{{ interfaceData.addMore }}</v-btn>
        <v-btn
          depressed
          outlined
          rounded
          class="mx-1"
          :disabled="enableShare"
          @click="shareViaEmail"
        >
          {{ shareText }}
        </v-btn>
      </v-form>
      <div class="mt-5 mb-2">{{ isLastStep ? interfaceData.currentConfigCopyText : 'Or copy the URL to your configurator: ' }}</div>
      <div class="share-input-wrap">
        <v-text-field
          :value="configURL"
          depressed
          rounded
          readonly
          filled
          dense
          hide-details
          class="copy-txt-field"
          @keypress.enter="copy"
        >
        </v-text-field>
        <v-btn
          depressed
          outlined
          absolute
          rounded
          height="100%"
          @click="copyToClipboard"
          :class="copiedSuccessClass"
          class="copy-btn ma-0">
          {{ copyText }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShareLink',
  data: function () {
    return {
      shareWasSentText: 'Congratulations! Your configuration has been successfully shared.',
      shareTextDefault: 'SHARE',
      shareText: 'SHARE',
      copyTextDefault: 'COPY',
      copyText: 'COPY',
      copiedSuccessClass: 'accent--text white',
      hide: false,
      valid: false,
      shareDisabled: false,
      email: '',
      email2: '',
      email3: '',
      email4: '',
      email5: '',
      emailRules: [
        v => !!v || this.interfaceData.emailRule1,
        v => this.emailRegex.test(v) || this.interfaceData.emailRule2
      ],
      additionalEmailRules: [
        v => v === '' || this.emailRegex.test(v) || this.interfaceData.emailRule2
      ],
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      showEmails: 1
    }
  },
  computed: {
    ...mapGetters([
      'APIURL_REFERAL',
      'interfaceData',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedAccessories',
      'configCode',
      'configURL',
      'isLastStep'
    ]),
    enableShare () {
      return (!this.valid || this.shareDisabled)
    }
  },
  created () {
    this.copyToClipboard = this.debounce(this.copyToClipboard)
  },
  mounted () {
    this.copyText = this.interfaceData.copyTextDefault
    this.copyTextDefault = this.interfaceData.copyTextDefault
    this.shareText = this.interfaceData.shareTextDefault
    this.shareTextDefault = this.interfaceData.shareTextDefault
    this.shareWasSentText = this.interfaceData.congratulations + ' <br> ' + this.interfaceData.shareWasSentText
  },
  methods: {
    validateForm () {
      return this.$refs.shareForm.validate()
    },
    shareViaEmail () {
      if (!this.validateForm()) {
        return
      }
      const userData = JSON.parse(this.$localStorage.get('user_data'))

      const emails = [this.email, this.email2, this.email3, this.email4, this.email5]

      this.shareText = this.interfaceData.sharedText
      this.shareDisabled = true

      return this.axios.put(this.APIURL_REFERAL, {
        id: userData.id,
        emails: emails,
        config_url: this.configURL,
        customerInfo: {
          user_email: userData.user_email
        },
        email_content: this.interfaceData.referralEmail,
        contact_email: this.interfaceData.contact_email
      })
        .then((resp) => {
          console.warn('SERVER RESPONSE: ', resp)
          if (resp.status) {
            this.trackEvent('SelectFieldOnStart', 'ButtonClick')
            const tm = setTimeout(() => {
              this.shareText = this.shareTextDefault
              this.copiedSuccessClass = 'accent--text white'
              this.shareDisabled = false
              this.$store.commit('CLOSE_SHARE_DIALOG')
              this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: this.shareWasSentText })
              clearTimeout(tm)
            }, 2000)
          }
        })
        .catch(err => {
          console.warn('sendEmail error: ', err)
          this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
        })
    },
    copyToClipboard () {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', e => e.stopPropagation())
      el.value = this.configURL
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.clipboardSuccessHandler()
    },
    clipboardSuccessHandler () {
      this.copiedSuccessClass = 'white--text accent'
      this.shareText = this.interfaceData.sharedText
      this.trackEvent('CopiedShareLink', 'ButtonClick')
      const tm = setTimeout(() => {
        this.shareText = this.shareTextDefault
        this.copiedSuccessClass = 'accent--text white'
        clearTimeout(tm)
      }, 2000)
    },
    clipboardErrorHandler () {
      this.copiedSuccessClass = 'red--text white'
      this.shareText = this.interfaceData.error
      const tm = setTimeout(() => {
        this.shareText = this.shareTextDefault
        this.copiedSuccessClass = 'accent--text white'
        clearTimeout(tm)
      }, 2000)
    }
  }
}

</script>

<style lang="scss" scoped>
.share-input-wrap {
  position: relative;
}

.copy-btn {
  top: 0;
  right: 0;
  border: 3px solid var(--v-accent-base) !important;
}
</style>
