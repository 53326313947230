<template>
  <v-dialog
    v-model="isLoginDialogVisible"
    max-width="700"
    content-class="elevation-0"
    persistent
  >
    <v-container>
      <div class="white flex-grow-1 rounded pt-5 px-5 mb-5">
        <div class="share font-weight-light py-8">
          <div class="mb-3 text-h5">{{ interfaceData.loginPopupText }}:</div>
          <div class="share-input-wrap">
            <v-form ref="popupRegisterForm" v-model="valid">
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  autofocus
                  required
                >
                <template #label>
                  {{ interfaceData.email_address }} <span class="red--text"><strong>*</strong></span>
                </template>
              </v-text-field>
             <v-btn depressed rounded block large
                outlined
                :disabled="!valid"
                @click="registerUser()"
                class="c-mesi-btn"
              >
                {{ interfaceData.see_configuration }}
              </v-btn>
            </v-form>
          </div>
        </div>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import fieldOfCareData from '@/data/MESI/fields.json'
import { mapGetters } from 'vuex'

export default {
  name: 'SharePopup',
  computed: {
    ...mapGetters([
      'interfaceData',
      'APIURL_REGISTER',
      'partnerURL',
      'isLoginDialogVisible'
    ]),
    fieldOfCareData () {
      return fieldOfCareData
    },
    itemsForCodeUrl () {
      const userData = JSON.parse(this.$localStorage.get('user_data')) ? JSON.parse(this.$localStorage.get('user_data')) : ''
      return {
        selected: this.$store.state.selected,
        preselected: this.$store.state.preselected,
        user_id: userData.id
      }
    }
  },
  data: function () {
    return {
      dialogVisible: true,
      valid: false,
      email: '',
      emailRules: [
        v => !!v || this.interfaceData.emailRule1,
        v => this.emailRegex.test(v) || this.interfaceData.emailRule2
      ],
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }
  },
  watch: {
    dialogVisible (visible) {
      if (visible) {
        this.$store.commit('SHOW_LOGIN_DIALOG')
      } else {
        this.closeDialog()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$store.commit('CLOSE_LOGIN_DIALOG')
    },
    validateForm () {
      return this.$refs.popupRegisterForm.validate()
    },
    registerUser () {
      if (!this.validateForm()) {
        return
      }
      const referredBy = this.decodeCode(this.$route.params.code).selected.referredByID
      return this.axios.post(this.APIURL_REGISTER, {
        id: '',
        session_id: '',
        referred_by: referredBy,
        partner: this.partnerURL,
        customerInfo: {
          user_email: this.email
        },
        partnerInfo: {
          name: this.partnerURL,
          email: this.interfaceData.contact_email,
          phone: this.interfaceData.contact_phone,
          address: this.interfaceData.contact_address
        }
      })
        .then((resp) => {
          console.warn('SERVER RESPONSE: ', resp)
          if (resp.status) {
            this.$localStorage.set('user_data', JSON.stringify(resp.data))
            const st = setTimeout(() => {
              this.closeDialog()
              this.updateShareUrl()
              // console.log('LOCAL STORAGE: ', JSON.parse(this.$localStorage.get('user_data')))
              clearTimeout(st)
            }, 1000)
          }
        })
        .catch(err => {
          console.warn('sendEmail error: ', err)
          // this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
        })
    },
    getShareURL (code) {
      const url = window.location.origin
      if (this.partnerURL === 'MESI') {
        return url + '/code/' + code
      } else {
        return url + '/' + this.partnerURL + '/code/' + code
      }
    },
    updateShareUrl () {
      const configCode = this.encodeDataForURL(this.itemsForCodeUrl)
      this.$store.commit('SET_CONFIG_CODE', configCode)

      const shareUrl = this.getShareURL(configCode)
      this.$store.commit('SET_CONFIG_URL', shareUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
