<template>
  <v-row id="summary" class="visual-block-step step-4-visual-block pa-6 mt-8 mb-12" :class="{ 'active-step' : isActiveStep}" ref="Summary">
    <v-col cols="6" class="my-auto px-0 d-none for-pdf-print">
      <img width="200" :src="require('@/assets/MESI-logo.svg')" alt="MESI logo" />
    </v-col>
    <v-col cols="6" class="my-auto px-0 d-none justify-end for-pdf-print">
      <img v-if="partnerLogo !== ''" :src="partnerLogo" width="200" />
    </v-col>
    <v-col cols="6" class="my-5 d-none for-pdf-print justify-space-between px-0">
      <div class="d-inline-block text-h5">
        <span>{{ interfaceData.title }}</span>
        <br><br>
        {{ interfaceData.date }}: {{ getDate }}
        <br>
        {{ interfaceData.total }}: <span class="font-weight-bold ml-2 accent--text">{{ numberFormatter.format(priceFull) }}</span>
      </div>
    </v-col>
    <v-col v-if="handlerInfo.length > 0" cols="6" class="my-5 d-none for-pdf-print justify-end">
      {{ interfaceData.distributor_contact }}:<br>
      {{ handlerInfo.partner_name }}<br>
      {{ handlerInfo.handler_email }}<br>
      {{ handlerInfo.handler_phone }}<br>
    </v-col>
    <CurrentSelectionListVisual id="visual-selection" ref="visual-selection" />
  </v-row>
</template>

<script>
import CurrentSelectionListVisual from '@/components/visualSteps/partials/CurrentSelectionListVisual.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SummaryVisual',
  props: {
    stepIndex: Number,
    selected: Array,
    systemData: Object,
    isActiveStep: Boolean
  },
  data () {
    return {
      handlerInfo: {}
    }
  },
  components: {
    CurrentSelectionListVisual
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'partnerURL',
      'steps',
      'priceFull',
      'priceFinal',
      'numberFormatter',
      'partnerLogo'
    ]),
    componentName () {
      return this.steps[this.stepIndex].componentName
    },
    getDate () {
      const date = new Date()
      const filename =
      ('0' + date.getDate()).slice(-2) + '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
      date.getFullYear()

      return filename
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }

    this.handlerInfo = JSON.parse(this.$localStorage.get('user_data')).handler_info !== undefined ? JSON.parse(this.$localStorage.get('user_data')).handler_info : {}
    // console.log('HANDLER INDP: ', this.handlerInfo)
  }
}
</script>
