<template>
  <div class="fill-height bg pt-5">
    <v-container class="white narrow-container">
      <div class="pa-5">
        <div class="d-flex justify-space-between">
          <div class="display-1 mb-5">{{ interfaceData.appTitle }}</div>
          <router-link :to="partnerURL === 'MESI' ? '/' : '/'+partnerURL">{{ interfaceData.termsConditions.backToApp }}</router-link>
        </div>
        <div class="title mb-5">{{ interfaceData.termsConditionsTitle }}</div>
          {{ interfaceData.termsConditions.content }}
        <router-link :to="partnerURL === 'MESI' ? '/' : '/'+partnerURL">{{ interfaceData.termsConditions.backToApp }}</router-link>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Terms',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Terms & Conditions'
  },
  computed: {
    ...mapGetters([
      'partnerURL',
      'interfaceData'
    ])
  }
}
</script>

<style lang="scss" scoped>
.narrow-container {
  max-width: 800px;
}
</style>
