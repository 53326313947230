<template>
  <div>
    <sectionTitle>
      {{ interfaceData.apps.title }}
      <template v-slot:subtitle>
      {{ interfaceData.apps.subtitle }}
      </template>
    </sectionTitle>
    <div class="pb-12">
      <v-row>
        <v-col
          v-for="(item, k) in filteredApps"
          :key="k"
          cols="6"
          md="4"
          lg="3"
          class="d-flex child-flex px-5 mb-5"
          >
          <div
            class="item"
            :class="itemClass(item)"
          >
            <imgCircle
              @click="toggleItem(item)"
              :item="item"
              :data-cy="'app-'+item.id"
              data-cy-app-item
              :src="imgPath(item.img)"
              :isSelected="isSelected(item)"
              :imgClass="itemClass(item)">
            </imgCircle>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import sectionTitle from '@/components/sectionTitle.vue'
import imgCircle from '@/components/imgCircle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Apps',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    sectionTitle, imgCircle
  },
  created () {
    this.toggleItem = this.debounce(this.toggleItem)
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'allAppsData',
      'steps',
      'allowedApplications',
      'preselectedApplications',
      'selectedApplications'
    ]),
    selected: {
      get () {
        return this.$store.getters.selectedApplications
      },
      set (newSelected) {
      }
    },
    filteredApps () {
      return this.allAppsData.filter((obj) => {
        return !this.isDisabled(obj.id)
      })
    }
  },
  methods: {
    toggleItem (item) {
      this.$store.dispatch('TOGGLE_APPLICATION', item.id)
    },
    isDisabled (id) {
      return this.isPreselected(id) || !this.allowedApplications.includes(id)
    },
    isPreselected (id) {
      return this.preselectedApplications.includes(id)
    },
    isSelected (item) {
      return this.selectedApplications.includes(item.id)
    },
    itemClass (item) {
      let classes = ''
      if (!this.allowedApplications.includes(item.id) && !this.isPreselected(item.id)) {
        classes += 'disabled'
      }
      return classes
    },
    imgPath: path => require('@/assets/apps/interactive/' + path)
  }
}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
}
</style>
