<template>
  <v-container fluid fill-height class="py-0">
    <v-btn @click="toggleVisualBlock" color="white" large rounded fixed bottom
      :class="{ 'mBotAdjust' : visualBlockVisibleMobile }"
      class="visualMobileBtnToggle" v-show="$vuetify.breakpoint.smAndDown">
      <v-icon v-show="!visualBlockVisibleMobile">{{ mdiImgIcon }}</v-icon><v-icon v-show="visualBlockVisibleMobile">mdi-close</v-icon>
    </v-btn>
    <v-btn @click="toggleVisualBlock" color="white" large rounded fixed bottom
      :class="{ 'mBotAdjust' : visualBlockVisibleMobile }"
      class="visualMobileBtnToggle" v-show="$vuetify.breakpoint.smAndDown">
      <v-icon v-show="!visualBlockVisibleMobile">{{ mdiImgIcon }}</v-icon><v-icon v-show="visualBlockVisibleMobile">mdi-close</v-icon>
    </v-btn>
    <v-row class="fill-height">
      <v-col id="visual-block-col" cols="12" md="6" class="py-0 fill-height d-flex visual-block-col"
      :class="visualBlockClasses">
        <!--div class="dev-only ma-3" style="border: 2px solid red">
          <v-sheet class="pa-3" color="lighten-5">
            <strong>SYSTEM</strong>: {{ selectedField }}
          </v-sheet>
          <v-sheet class="pa-3" color="primary lighten-5">
            <strong>EXTENSIONS/MODULES:</strong>
            <v-row>
              <v-col cols="4">selected: {{ selectedExtensionsModules }} </v-col>
              <v-col cols="4">disabled: {{ disabledExtModules }} </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="pa-3">
            <strong>APPS:</strong>
            <v-row>
              <v-col cols="12">PRE-selected: {{ preselectedApplications }} </v-col>
              <v-col cols="12">selected: {{ selectedApplications }} </v-col>
              <v-col cols="12">Allowed: {{ allowedApplications }} </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="pa-3" color="primary lighten-5">
            <strong>ACCESSORIES</strong>:
            <v-row>
              <v-col cols="12">PRE-selected: {{ preselectedAccessories }} </v-col>
              <v-col cols="12">selected: {{ selectedAccessories }} </v-col>
              <v-col cols="12">Allowed: {{ allowedAccessories }} </v-col>
            </v-row>
          </v-sheet>
        </div-->
        <visual-block :systemData="systemData" :fieldOfCareData="fieldOfCareData" :key="visualBlockKey" />
      </v-col>
      <InfoBlock />
      <v-col cols="12" md="6" class="py-0 bg interactive-block-col fill-height">
        <interactive-block :systemData="systemData" :fieldOfCareData="fieldOfCareData" />
      </v-col>
    </v-row>
    <!-- <StartNoticeDialog v-if="showStartNoticeDialog" /> -->
    <!--MobileNotice /-->
    <ThankYouDialog v-if="isThankYouDialogVisible" />
    <LoginDialog v-if="isLoginDialogVisible" />
    <GenericDialog v-if="isGenericDialogVisible" />
    <selectionDisabledSnackbar />
    <GeneralSnackbar />
  </v-container>
</template>

<script>
import fieldOfCareData from '@/data/MESI/fields.json'
import visualBlock from '@/components/VisualBlock.vue'
import interactiveBlock from '@/components/InteractiveBlock.vue'
import InfoBlock from '@/components/InfoBlock.vue'

// import MobileNotice from '@/components/dialogs/MobileNotice.vue'
// import StartNoticeDialog from '@/components/dialogs/StartNoticeDialog.vue'
import LoginDialog from '@/components/dialogs/LoginDialog.vue'
import GenericDialog from '@/components/dialogs/GenericDialog.vue'
import ThankYouDialog from '@/components/dialogs/ThankYouDialog.vue'
import SelectionDisabledSnackbar from '@/components/snackbars/SelectionDisabled.vue'
import GeneralSnackbar from '@/components/snackbars/General.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    visualBlock,
    interactiveBlock,
    InfoBlock,
    // StartNoticeDialog,
    LoginDialog,
    GenericDialog,
    ThankYouDialog,
    SelectionDisabledSnackbar,
    GeneralSnackbar// MobileNotice
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // if we come from different view we gotta sync our data from vuex
      // (only extensions/modules are set, this then refreshes apps/accessories accordingly)
      // it also updates all prices
      if (from.path.includes('start')) {
        vm.$store.commit('SYNC_DATA')
      }
    })
  },
  created () {
    if (typeof this.$route.params.code !== 'undefined') {
      if (!(this.$router.currentRoute.name === 'ReferralPartner' || this.$router.currentRoute.name === 'Referral')) {
        this.loadConfigurationForCode(this.$route.params.code)
      }
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'partnerURL',
      'isDistributer',
      'isShareDialogVisible',
      'isLastStep',
      'selectedField',
      'selectedExtensionsModules',
      'disabledExtModules',
      'preselectedApplications',
      'selectedApplications',
      'allowedApplications',
      'preselectedAccessories',
      'selectedAccessories',
      'allowedAccessories',
      'visualBlockKey',
      'isLoginDialogVisible',
      'isGenericDialogVisible',
      'isThankYouDialogVisible',
      'visualBlockVisibleMobile',
      'configCodeSeparator'
    ]),
    mdiImgIcon () {
      return this.isLastStep ? 'mdi-cart' : 'mdi-image-area'
    },
    showStartNoticeDialog () {
      return !this.$localStorage.get('mConf_startNoticePopupClosed', false)
    },
    fieldOfCareData () {
      return fieldOfCareData
    },
    systemDataOk () {
      console.log('TYPEOF DATA: ', typeof this.systemData !== 'undefined')
      return typeof this.systemData !== 'undefined'
    },
    systemData () {
      if (typeof this.selectedField === 'undefined') {
        return {}
      }
      return this.getObjectWithIDFromArr(fieldOfCareData, this.selectedField)
    },
    visualBlockClasses () {
      return {
        mobile: this.$vuetify.breakpoint.smAndDown,
        show: this.visualBlockVisibleMobile,
        'align-center': this.isDesktop()
      }
    }
  },
  methods: {
    loadConfigurationForCode (code = '') {
      const data = this.decodeCode(code)
      const userData = JSON.parse(this.$localStorage.get('user_data')) ? JSON.parse(this.$localStorage.get('user_data')) : null
      this.$store.dispatch('SET_DATA_FROM_CODE', [data, userData])
    },
    toggleVisualBlock () {
      this.$store.commit('SET_VISUAL_BLOCK_VISIBLE_MOBILE', !this.visualBlockVisibleMobile)
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped lang="scss">
.interactive-block-col {
  border-bottom-left-radius: 7rem;
}

.visual-block-col {
  position: relative;
  overflow-x: hidden;
  transition: 0.4s all;

  &.mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 9;
    margin-left: -100%;
    overflow-y: auto;

    &.show {
      margin-left: 0;
    }
  }
}

.visualMobileBtnToggle {
  z-index: 99999 !important;
  width: 100px;
  left: -20px;

  &.mBotAdjust {
    margin-bottom: 6rem;
  }
}

.distributerShareBtn {
  z-index: 99999 !important;
  width: 100px;
  margin-top: 8px;
  top: 0;
  left: 25%;
  transform: translateX(-50%);

  &.mBotAdjust {
    top: 8%;
    margin-top: 0;
    left: -20px;
    margin-bottom: 6rem;
    transform: none;
  }
}
</style>
