<template>
  <div class="price-wrap text-left">
    <div class="price-div display-2">
      <div class=" d-flex subtitle-1 justify-space-between">
        <small>{{ isLastStep ? interfaceData.yourFinalConfig : interfaceData.yourConfig }}:</small>
        <v-icon v-if="!isLastStep && !showInfoBlock" x-large color="grey lighten-1" class="ml-5">mdi-magnify</v-icon>
      </div>
      <span class="font-weight-bold accent--text" data-cy-price>{{ numberFormatter.format(priceFull) }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PriceDisplay',
  props: {
    showInfoBlock: Boolean
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'isLastStep',
      'allExtModData',
      'price',
      'priceGreenPackage',
      'pricePremiumPackage',
      'selectedExtensionsModules',
      'selectedApplications',
      'defaultPriceMTAB',
      'priceFull',
      'priceFinal',
      'numberFormatter'
    ]),
    justForWatch () {
      return [
        this.selectedExtensionsModules,
        this.selectedApplications
      ]
    },
    visualPriceWithMTABLET () {
      const mtabletIncluded = this.isMtabletIncludedInSelection(this.selectedExtensionsModules.slice(), this.allExtModData)
      if (!mtabletIncluded && this.selectedExtensionsModules.length > 0) {
        // console.warn('mTABLET not included (WE ADD 19 - ONLY VISUALLY)')
        return this.price + this.defaultPriceMTAB
      }
      return this.price
    }
  },
  mounted () {
    this.updateFinalPrices()
  },
  methods: {
    updateFinalPrices () {
      this.$nextTick(() => {
        const mtabletIncluded = this.isMtabletIncludedInSelection(this.selectedExtensionsModules, this.allExtModData)
        if (!mtabletIncluded && this.selectedExtensionsModules.length > 0) {
          console.log('watch() update FINAL PRICES with mTABLET default price')
          this.$store.commit('SET_PRICE_FINAL', (this.price + this.defaultPriceMTAB))
          this.$store.commit('SET_PRICE_GREEN_FINAL', (this.priceGreenPackage + this.defaultPriceMTAB))
          this.$store.commit('SET_PRICE_PREMIUM_FINAL', (this.pricePremiumPackage + this.defaultPriceMTAB))
        } else {
          this.$store.commit('SET_PRICE_FINAL', this.price)
          this.$store.commit('SET_PRICE_GREEN_FINAL', this.priceGreenPackage)
          this.$store.commit('SET_PRICE_PREMIUM_FINAL', this.pricePremiumPackage)
        }
      })
    }
  },
  watch: {
    justForWatch () {
      this.updateFinalPrices()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
