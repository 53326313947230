<template>
  <v-dialog
    v-model="isResetDialogVisible"
    content-class="elevation-0"
    width="500"
    overlay-color="black"
    overlay-opacity="0.5"
    persistent
  >
    <v-container class="text-center">
      <v-btn block text depressed color="white" @click="closeDialog">
        <span class="text-decoration-underline">{{ interfaceData.close }}</span>
      </v-btn>
      <div class="flex-grow-1 white rounded px-5 py-9 mb-5">
        <div class="d-flex justify-center">
          <v-img :src="src" aspect-ratio="1" contain width="100" class="flex-grow-0 mb-4" />
        </div>
        <h3>{{ interfaceData.are_you_sure }}</h3>
        <p class="mt-3">{{ interfaceData.config_reset }}</p>
        <v-btn @click="reset" color="accent" class="mx-2 text-uppercase">{{ interfaceData.reset }}</v-btn>
        <v-btn @click="closeDialog" color="accent" class="mx-2 text-uppercase">{{ interfaceData.cancel }}</v-btn>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ResetDialog',
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'isResetDialogVisible'
    ])
  },
  methods: {
    closeDialog () {
      this.$store.commit('CLOSE_RESET_DIALOG')
    },
    reset () {
      this.$router.go(0)
    }
  }
}
</script>
