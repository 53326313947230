<template>
  <div class="fill-height bg pt-5 text-center">
    <v-container class="narrow-container">
      <img :src="src" width="120" class="mx-auto" />
    </v-container>

    <v-container class="white narrow-container">
      <div class="pt-5 px-5">
        <div class="mb-10">
          <div class="display-1 mb-3">{{ interfaceData.notFound.whoops }}</div>
          <div class="headline">{{ interfaceData.notFound.notExist }}</div>
        </div>
        <div>
          <v-btn rounded depressed large color="accent" to="start">{{ interfaceData.notFound.newConfig }}</v-btn>
        </div>
        <div class="mt-7">
          <v-btn  text to="terms-conditions" color="primary lighten-2" class="mt-3">{{ interfaceData.termsConditionsTitle }}</v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'a404',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '404 Not Found'
  },
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData'
    ])
  }
}
</script>

<style lang="scss" scoped>
.narrow-container {
  max-width: 800px;
}
</style>
