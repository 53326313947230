<template>
  <v-dialog
    v-model="showDialog"
    content-class="elevation-0"
    width="700"
    overlay-color="black"
    overlay-opacity="0.5"
    persistent
  >
    <v-container class="text-center">
      <div class="flex-grow-1 white rounded px-5 py-9 mb-5">
        <img :src="src" width="150" />
        <div class="my-5" :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-3': $vuetify.breakpoint.mdAndUp}"><strong>{{ interfaceData.dialogThankYou.text1 }}</strong> {{ interfaceData.dialogThankYou.text2 }}</div>
        <div class="headline">{{ interfaceData.dialogThankYou.text3 }}</div>
      </div>
      <v-btn color="accent" block x-large rounded depressed class="subtitle-1" @click="closeDialogs">{{ interfaceData.dialogThankYou.btn }}</v-btn>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean
  },
  computed: {
    ...mapGetters([
      'interfaceData'
    ]),
    showDialog () {
      return this.show
    }
  },
  data: function () {
    return {
      src: require('@/assets/placeholder-mesi.svg')
    }
  },
  methods: {
    closeDialogs () {
      this.$store.commit('CLOSE_ALL_DIALOGS')
    }
  }
}
</script>
