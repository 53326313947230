import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Terms from '../views/Terms.vue'
import ThankYou from '../views/ThankYou.vue'
import a404 from '../views/a404.vue'

Vue.use(VueRouter)

const partners = ['fr', 'de', 'es', 'it', 'medprax', 'hiberniamedical', 'bluemed', 'promedeus', 'partnerTest']

function isValid (param) {
  // check if param is valid
  for (const id of partners) {
    if (param === id) {
      return true
    }
  }
  return false
}

const routes = [
  {
    path: '/start',
    name: 'Start',
    component: Start
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/code/:code',
    name: 'Code',
    component: Home
  },
  {
    path: '/referral/:code',
    name: 'Referral',
    component: Home
  },
  {
    path: '/terms-conditions',
    name: 'Terms & Conditions',
    component: Terms
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: ThankYou
  },
  {
    // will match everything
    path: '*',
    name: '404',
    component: a404
  },
  // Routes if it's partners page
  {
    path: '/:id/start',
    name: 'StartPartner',
    component: Start,
    beforeEnter: (to, from, next) => {
      if (!isValid(to.params.id)) {
        next({ name: '404' })
      }

      next()
    }
  },
  {
    path: '/:id',
    name: 'HomePartner',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!isValid(to.params.id)) {
        next({ name: '404' })
      }

      next()
    }
  },
  {
    path: '/:id/code/:code',
    name: 'CodePartner',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!isValid(to.params.id)) {
        next({ name: '404' })
      }

      next()
    }
  },
  {
    path: '/:id/referral/:code',
    name: 'ReferralPartner',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!isValid(to.params.id)) {
        next({ name: '404' })
      }

      next()
    }
  },
  {
    path: '/:id/terms-conditions',
    name: 'Terms & Conditions Partner',
    component: Terms,
    beforeEnter: (to, from, next) => {
      if (!isValid(to.params.id)) {
        next({ name: '404' })
      }

      next()
    }
  },
  {
    path: '/:id/thank-you',
    name: 'Thank You Partner',
    component: ThankYou,
    beforeEnter: (to, from, next) => {
      if (!isValid(to.params.id)) {
        next({ name: '404' })
      }

      next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeRouteEnter = (to, from, next) => {
  next(vm => {
    vm.previousRoute = from
  })
}

export default router
