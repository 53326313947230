<template>
  <div class="items-wrap">
    <sectionTitle>
      {{ interfaceData.extmods.title }}
      <template v-slot:subtitle>
        {{ interfaceData.extmods.subtitle }}
        <v-tooltip bottom max-width="300">
          <template v-slot:activator="{ on }">
            <v-icon color="grey lighten-1" v-on="on">mdi-information</v-icon>
          </template>
          <div>
            {{ interfaceData.extmods.description }}
          </div>
        </v-tooltip>
      </template>
    </sectionTitle>

    <div class="pb-12">
      <v-row>
        <template
          v-for="(item,k) in allExtModData"
        >
          <v-col
            v-if="!item.hideAsSelection"
            :key="k"
            cols="6"
            md="4"
            lg="3"
            class="d-flex child-flex px-5 mb-5"
          >
            <div
              class="item"
              :class="itemClass(item)"
            >
              <imgCircle
                @click="toggleItem(item)"
                :item="item"
                :src="imgPath(item.img)"
                :isAvailableAsApp="isAvailableAsApp(item)"
                :isSelected="isSelected(item)"
                data-cy-ext-mod
                :data-cy="'ext-mod-'+item.name"
                :imgClass="itemClass(item)">
              </imgCircle>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import sectionTitle from '@/components/sectionTitle.vue'
import imgCircle from '@/components/imgCircle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ExtensionsModules',
  components: {
    sectionTitle, imgCircle
  },
  props: {
    systemData: Object,
    item: Object
  },
  data: function () {
    return {
      selectedKey: 'extensionsModules'
    }
  },
  created () {
    this.toggleItem = this.debounce(this.toggleItem)
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'allExtModData',
      'steps',
      'disabledExtModules',
      'selectedExtensionsModules',
      'selectionDisabledSnackbarVisible'
    ]),
    systemSelected () {
      return typeof this.systemData !== 'undefined'
    },
    activeStep () {
      return this.$store.getters.activeStep + 1
    },
    preselectedExtModules () {
      if (!this.systemSelected) {
        return []
      }
      return this.systemData.extensionsModules.included
    }
  },
  methods: {
    toggleItem (obj) {
      if (this.isDisabled(obj)) {
        return
      }

      this.$store.dispatch('TOGGLE_EXTENSION_MODULE', obj.id)
    },
    isAvailableAsApp (item) {
      return this.isDisabled(item) && !item.comingSoon
    },
    isSelected (item) {
      return this.selectedExtensionsModules.includes(item.id)
    },
    isDisabled (obj) {
      return obj.comingSoon || this.disabledExtModules.includes(obj.id)
    },
    itemClass (obj) {
      let classes = ''
      if (this.isDisabled(obj)) {
        classes += 'disabled'
      }
      return classes
    },
    imgPath: path => require('@/assets/extModules/interactive/' + path)
  }
}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
}
</style>
