<template>
  <v-snackbar
    class="m-snackbar"
    vertical
    bottom
    right
    light rounded
    :timeout="8000"
    :max-width="300"
    v-model="visible"
    @input="close"
  >
    <h3 class="mb-3">{{ interfaceData.hooray }}</h3>
    {{ selectionDisabledText }}
    <!--div class="my-3">Disabled items:</div>
    <ul>
      <li v-for="(itm, k) in disabledItemsBySelectingAnother" :key="k">
        {{ extModByID(itm).subtext }}
      </li>
    </ul-->
    <v-btn
      depressed
      @click="close"
      cy-data-close-hooray-popup
    >
      {{ interfaceData.close }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectionDisabledSnackbar',
  data: function () {
    return {
      visible: false
    }
  },
  watch: {
    selectionDisabledSnackbarVisible (val) {
      console.warn('watch: selectionDisabledSnackbarVisible', val)
      this.visible = val
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'disabledItemsBySelectingAnother',
      'extModByID',
      'selectionDisabledSnackbarVisible',
      'selectionDisabledText'
    ])
  },
  methods: {
    close () {
      this.$store.commit('HIDE_SELECTION_DISABLED_SNACKBAR')
    }
  }
}
</script>

<style lang="scss">
/* All of these should work via props but they do not! */
.m-snackbar {
  left: auto !important;

  .v-snack__wrapper {
    background: white !important;
    color: var(--v-primary-base) !important;
    max-width: 550px;
    border-radius: 1rem !important;
  }
}
</style>
