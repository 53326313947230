<template>
  <div>
    <template v-for="extModID in alwaysSelectedExtMods">
      <PresentationBlock :extModID="extModID" :key="extModID" />
    </template>

    <template v-for="extModID in selectedExtensionsModulesModified">
      <PresentationBlock :extModID="extModID" :key="extModID" />
    </template>

    <v-row class="m-block">
      <v-col cols="12" class="title">
        {{ interfaceData.additionalApps }}
      </v-col>
      <v-col cols="12 d-flex justify-space-between">
        <div class="flex-grow-1">
          <ul>
            <li
              v-for="id in additionalApps"
              :key="id">
              {{ applicationByID(id).name }} {{ interfaceData.app }} <strong class="accent--text">({{ numberFormatter.format(applicationByID(id).fullPrice) }})</strong>
            </li>
          </ul>
        </div>
        <div class="text-right m-mw-area" :class="{ 'm-mw-area-lg' : $vuetify.breakpoint.mdAndUp }">
          <div
            v-for="id in additionalApps"
            :key="id"
            class="d-inline-block ml-2 mb-2 m-icon-wrap">
            <v-img
              :src="imgPathApps(applicationByID(id).imgVisual)"
              width="70"
              aspect-ratio="1"
              class="pdf-change-image"
              :title="id"
              :alt="id"
              contain>
            </v-img>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="m-block">
      <v-col cols="12" class="title">
        {{ interfaceData.additionalAccessories }}
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div class>
            <ul>
              <li
                v-for="id in additionalAccessories"
                :key="id">
                {{ accessoryByID(id).name }} <strong v-if="accessoryByID(id).fullPrice" class="accent--text">({{ numberFormatter.format(accessoryByID(id).fullPrice) }})</strong>
              </li>
            </ul>
          </div>
          <div class="text-right m-mw-area" :class="{ 'm-mw-area-lg' : $vuetify.breakpoint.mdAndUp }">
            <div
              v-for="id in additionalAccessories"
              :key="id"
              class="d-inline-block ml-2 mb-2 m-icon-wrap">
              <imgCircle
                :item="accessoryByID(id)"
                :src="imgPathAccs(accessoryByID(id).img)"
                isStatic />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="m-block">
      <v-col cols="12" class="title">
        {{ interfaceData.layout }}
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div class>
            <strong>{{ interfaceData.layout }}</strong>
            <ul>
              <li>
                {{ layoutByID(selectedLayout).name }}
              </li>
            </ul>
          </div>
          <div class="text-right m-mw-area" :class="{ 'm-mw-area-lg' : $vuetify.breakpoint.mdAndUp }">
            <div class="d-inline-block ml-2 mb-2 m-icon-wrap">
              <imgCircle
                :item="layoutByID(selectedLayout)"
                :src="imgPathLayouts(layoutByID(selectedLayout).img)"
                isStatic />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import imgCircle from '@/components/imgCircle.vue'
import PresentationBlock from '@/components/PresentationBlock.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentSelectionListVisual',
  props: {
    fieldOfCareData: Array
  },
  components: {
    imgCircle,
    PresentationBlock
  },
  data: function () {
    return {
      mtabletImg: require('@/assets/extModules/interactive/MTABSYSW.jpg'),
      lazyImgSrc: require('@/assets/placeholder.jpg')
    }
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'extModByID',
      'applicationByID',
      'layoutByID',
      'accessoryByID',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedLayout',
      'preselectedApplications',
      'selectedAccessories',
      'preselectedAccessories',
      'numberFormatter'
    ]),
    selectedExtensionsModulesModified () {
      if (this.selectedExtensionsModules.includes('TBISYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
        // remove TBISYSCS4
        // add TBPSYSCS4
        const modifiedSelected = this.removeItemsFromArray(this.selectedExtensionsModules, ['TBISYSCS4'])
        modifiedSelected.push('TBPSYSCS4')
        this.$store.commit('MODIFY_SELECTED_EXT_MODS', modifiedSelected)
        return modifiedSelected
      }
      this.$store.commit('MODIFY_SELECTED_EXT_MODS', this.selectedExtensionsModules)
      return this.selectedExtensionsModules
    },
    additionalApps () {
      return this.selectedApplications.filter((el) => {
        return !this.preselectedApplications.includes(el)
      })
    },
    additionalAccessories () {
      return this.selectedAccessories.filter((el) => {
        return !this.preselectedAccessories.includes(el)
      })
    },
    alwaysSelectedExtMods () {
      return ['MTABSYSW']
    }
  },
  methods: {
    extNiceName (id) {
      const obj = this.extModByID(id)
      return obj.name
    },
    appNiceName (id) {
      const obj = this.applicationByID(id)
      return obj.name
    },
    accNiceName (id) {
      const obj = this.accessoryByID(id)
      return obj.name
    },
    includesList (id) {
      const obj = this.extModByID(id)
      return obj.includesList
    },
    imgPathExtMod: path => require('@/assets/extModules/interactive/' + path),
    imgPathApps: path => require('@/assets/apps/visual/' + path),
    imgPathLayouts: path => require('@/assets/layout/visual/' + path),
    imgPathAccs: path => require('@/assets/accessories/visual/' + path)
  }
}
</script>

<style lang="scss" scoped>
$imgw: 70px;

.m-mw-area {
  max-width: 190px;

  &.m-mw-area-lg {
    max-width: 330px;
  }
}

.m-block {
  border: 1px solid var(--v-secondary-lighten5);
  border-radius: 1rem;
  margin-bottom: 1rem;

  .title {
    padding-bottom: 0;
  }
}

ul {
  vertical-align: top;
}

.m-icon-wrap {
  max-width: $imgw;
  vertical-align: top;
  margin-bottom: 3px;

  .caption {
    line-height: 1.2;
    margin-top: 3px;
  }
}

.img {
  border: 2px solid var(--v-secondary-lighten5);
  border-radius: 50%;
  max-width: $imgw;
}
</style>
