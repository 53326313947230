<template>
  <transition name="slide-fade">
    <v-snackbar
      :bottom="true"
      :left="true"
      :absolute="$vuetify.breakpoint.mdAndUp"
      color="bg"
      :timeout="timeout"
      :value="showSnackbar"
      depressed
      light
      transition="slide-y-reverse-transition"
      class="c-info-block d-flex flex-column align-center text-left"
      :class="{ 'width-lg' : $vuetify.breakpoint.mdAndUp }"
      @click.native="showInfoBlockNow"
    >
      <transition name="slide-fade">
        <div v-show="showInfoBlock" class="info-block__more pt-3 pb-5" transition="slide-y-transition">
          <div class="d-flex justify-end"><v-icon right v-if="showInfoBlock" x-large color="grey lighten-1">mdi-close</v-icon></div>
          <CurrentSelectionList />
        </div>
      </transition>
      <PriceDisplay :showInfoBlock="showInfoBlock" />
    </v-snackbar>
  </transition>
</template>

<script>
import PriceDisplay from '@/components/PriceDisplay.vue'
import CurrentSelectionList from '@/components/visualSteps/partials/CurrentSelectionList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InfoBlock',
  components: {
    PriceDisplay,
    CurrentSelectionList
  },
  data: function () {
    return {
      showInfoBlock: false,
      timeout: -1
    }
  },
  computed: {
    ...mapGetters([
      'extModIsSelected',
      'isLastStep',
      'defaultPriceMTAB',
      'visualBlockVisibleMobile'
    ]),
    showSnackbar () {
      const showIt = this.extModIsSelected && !this.isLastStep
      if (this.$vuetify.breakpoint.mdAndUp) {
        return showIt
      }
      return this.visualBlockVisibleMobile && showIt
    }
  },
  methods: {
    showInfoBlockNow () {
      this.showInfoBlock = !this.showInfoBlock
      this.trackEvent('infoBlockOpen', 'ButtonClick')
    }
  }
}
</script>

<style lang="scss">

.c-info-block {
  $border: 1px solid var(--v-bg-base);
  bottom: 0 !important;
  padding-bottom: 0 !important;
  color: var(--v-primary-base) !important;
  z-index: 200 !important; // we want this one below dialogs
  height: auto !important;
  cursor: pointer;

  &.width-lg {
    width: 50% !important;
  }

  .v-snack__content {
    max-height: 100vh;
    overflow: auto;
  }
}

.info-block__more {
  width: 100%;
}

.v-snack__wrapper {
  margin: 0 !important; // otherwise its 0 auto and doesnt go mid because its flex
  border-radius: 0 !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.v-snack__content {
  flex-direction: column;
}

$transitionSpeed: 0.4s;
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all $transitionSpeed ease-in-out;
}
.slide-fade-leave-active {
  transition: none;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
